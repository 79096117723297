.obs {
    background-color: transparent;
    color: white;
}

.obs.obs-grid {
    border-color: white;
}

textarea {
    background-color: rgba(10, 2, 36, 0.8);
    border-radius: 0.2rem;
    font-family: inherit;
    color: white;
    border: 0.125rem solid #4C4C7E
}

@font-face {
    font-family: "BlissPro";
    src: url("../public/BlissPro.woff2") format("woff2");
}

.triangoloEq {
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 54px solid white;
    transform: translate(0px, 30px)
}

.OBS-domanda-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
    white-space: pre-line;
}

.OBS-domanda {
    display: block;
    vertical-align: middle;
    text-align: center;
    font-family: BlissPro, serif;
    font-weight: 600;
    width: 100%;
}